import { AuthService } from '@app/services/auth.service';
import { ProductModel } from '@app/models/product.model'

export class Rate {
    amount: number;
    currency: string;
    constructor(amount: number, currency: string) {
        this.amount = amount;
        this.currency = currency;
    }
}

export class Quantity {
    qty: number;
    uom: string;
    constructor(qty: number, uom: string) {
        this.qty = qty;
        this.uom = uom;
    }
}

export class ServiceCharge {
    serviceChargeAmount:number;
    serviceChargePercentage:number;
    serviceChargeTaxPercentage:number;
    serviceChargeTaxHsn:number;
    constructor(serviceChargeAmount:number,
        serviceChargePercentage:number,
        serviceChargeTaxPercentage:number,
        serviceChargeTaxHsn:number) {
        this.serviceChargeAmount = serviceChargeAmount;
        this.serviceChargePercentage = serviceChargePercentage;
        this.serviceChargeTaxHsn=serviceChargeTaxHsn;
        this.serviceChargeTaxPercentage=serviceChargeTaxPercentage
    }
}

export class Sa {
    id: string;
    itemId: string;
    constructor(id: string, itemId: string) {
        this.id = id;
        this.itemId = itemId;
    }
}

export class Delivery {
    customerDueDeliveryDate: number;
    constructor(customerDueDeliveryDate: number) {
        this.customerDueDeliveryDate = customerDueDeliveryDate;
    }
}

export class Tax {
    taxType: string;
    taxValueType: string;
    taxValue: number;
    constructor(taxType: string, taxValueType: string, taxValue: number) {
        this.taxType = taxType;
        this.taxValueType = taxValueType;
        this.taxValue = taxValue;
    }
}

export class Tolerance {
    tolerance: number;
    constructor(tolerance: number) {
        this.tolerance = tolerance;
    }
}  

/**
 * Purchase Order Item Model contains all fields related to purchased item.
 *
 * @author Kuldeep Dangi <kuldeep.dangi@moglix.com>
 */
export class PuchaseOrderItemModel {
    _id: string;
    buyerId: number;
    product: ProductModel;
    tax: Tax;
    rate: Rate;
    quantity: Quantity;
    sa: Sa;
    delivery: Delivery;
    invoiceSrNo: string;
    extraDetails: Tolerance;
    sourcerEmail: string;
    remark: string;
    rfqItemID: string;
    arcRfqType: string;
    originalItemId: string;
    // isDirectPo: string;
    l1Category: string;
    l2Category: string;
    serviceCharge:ServiceCharge;
    serviceChargeAmount:number;
    serviceChargePercentage:number;
    serviceChargeTaxPercentage:number;
    serviceChargeTaxHsn:number;
    ld: boolean;

    constructor(_id: string, buyerId: number, product: ProductModel, qty: number, uom: string,
        amount: number, currency: string, tax: Tax, sa = null, customerDueDeliveryDate?: number, invoiceSrNo?: string, tolerance?: number, sourcerEmail?: string, remark?: string, rfqItemID?: string, arcRfqType? : string, originalItemId? : string, l1Category?: string, l2Category?: string, serviceChargeAmount?:number,
        serviceChargePercentage?:number,
        serviceChargeTaxPercentage?:number,
        serviceChargeTaxHsn?:number, ld?: boolean ) {
        this._id = "";
        this.buyerId = buyerId;
        this.product = product;
        this.quantity = new Quantity(qty, uom);
        this.rate = new Rate(amount, currency);
        this.tax = tax;
        this.delivery = new Delivery(customerDueDeliveryDate);
        invoiceSrNo ? this.invoiceSrNo = String(invoiceSrNo) : null;
        sa ? this.sa = sa : null;
        this.extraDetails = new Tolerance(tolerance);
        sourcerEmail ? this.sourcerEmail = String(sourcerEmail) : null;
        remark ? this.remark = String(remark) : null;
        rfqItemID ? this.rfqItemID = String(rfqItemID) : null;
        arcRfqType ? this.arcRfqType = String(arcRfqType) : null;
        originalItemId ? this.originalItemId = String(originalItemId) : null;
        l1Category ? this.l1Category = String(l1Category) : null;
        l2Category ? this.l2Category = String(l2Category) : null;
        serviceChargeAmount ? this.serviceChargeAmount=serviceChargeAmount:null;
        serviceChargePercentage ? this.serviceChargePercentage=serviceChargePercentage :null;
        serviceChargeTaxPercentage ? this.serviceChargeTaxPercentage=serviceChargeTaxPercentage : null;
        serviceChargeTaxHsn ? this.serviceChargeTaxHsn=serviceChargeTaxHsn : null;
        this.serviceCharge=new ServiceCharge(serviceChargeAmount,serviceChargePercentage,serviceChargeTaxPercentage,serviceChargeTaxHsn);
        //isDirectPo ? this.isDirectPo = String(isDirectPo) : null;
        [true, false].includes(ld) ? this.ld = ld : null
        return this;
    }

}
