import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { ApiService } from './api.service';
import { environment as env } from '../../environments/environment';
import { PARAMS } from '../config/params'
import { BehaviorSubject, Observable, ReplaySubject } from 'rxjs';
import { AlertMessageService } from './alert-message.service';
import { ValidationService } from './helpers/validation.service';
import { PlantModel } from '@app/models/plant.model';
import * as lodash from 'lodash';
import { GroupModel } from '@app/models/group.model';
import { TeamModel } from '@app/models/team.model';
import { AppProgressBarService } from './app-progress-bar.service';
import { MESSAGES } from '@app/config/messages';
import { TeamsService } from '@app/modules/account-services/teams/teams.service';
import { PoService } from './po.service';
import { CommonService } from './common.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  public sessionObj: any = {};
  public companyId: string = '';
  public userId: string = '';
  public userName: string = '';
  public lastPlantId: number = 1;
  public groups: any = {};
  public plantsAgainstTeam: any = [];
  public teamsFromHistory: any = [];
  public teams: any = [];
  public allTeams: Array<any> = [];
  public companyToPlants: any = {};
  public buildSessionCompleted: boolean = false;
  public companies: Array<any> = [];
  public teamPlantMappingData: any;
  public moduleList: Array<any> = [];
  public eocModuleAccessRoleList: ReplaySubject<Array<any>> = new ReplaySubject<[]>();
  public moduleLIstChange: ReplaySubject<Array<any>> = new ReplaySubject<[]>();
  public accessObj:Object =  {};

  public static readonly AUTH_TOKEN = 'token';
  public static readonly SALESOPS_TOKEN = 'Authorization';
  public static readonly SEARCH_SUPPLIER_TOKEN = 'profileApiKey';
  public static readonly AUTHORIZATION_TOKEN = 'Authorization';
  public static readonly AUTH_USER_ID = 'idUser';
  public static readonly AUTH_BRANCH_ID = 'idBranch';
  public static readonly AUTH_COMPANY_ID = 'idCompany';
  public static readonly AUTH_APPLICATION_ID = 'application';
  public static readonly APPLICATION = 'source';
  public static readonly STORED_PLANTS = 'selectedPlants';
  public static readonly STORED_TEAMS = 'selectedTeam';
  public static readonly STORED_WAREHOUSES = 'selectedWarehouse';
  public static readonly STORED_GROUPS = 'selectedGroups';
  public static readonly EMAIL_ID = 'email';
  designation: any;
  businessDesignation: any;
  userRoleList: Array<string> = [];
  openPoPlants: Array<any> = [];
  openPlatListobs = new BehaviorSubject<any>([]);
  isManager: boolean = false;
  businessDesignationType: number;
  isGetCustomerPocApiCallingCompleted: boolean = false;
  isGetRegionsApiCallingCompleted: boolean = false;
  isGetPaymentTermsApiCallingCompleted: boolean = false;
  isGetSupplierListApiCallingCompleted: boolean = false;
  customerPocList: Array<any> = [];
  customerPocListObs: BehaviorSubject<Array<string>> = new BehaviorSubject<Array<string>>([]);
  regionList: Array<any> = [];
  paymentTermsList: Array<any> = [];
  supplierList: Array<any> = [];
  supplierListObs: BehaviorSubject<Array<any>> = new BehaviorSubject<Array<any>>([]);
  bulkPOCustomerList: string = "";

  constructor(private router: Router, private apiService: ApiService,
    private alertMessageService: AlertMessageService,
    private validationService: ValidationService,
    private AppProgressBarService: AppProgressBarService,
    private teamService: TeamsService,
    private poService: PoService,
    private commonService: CommonService) { }

  public getToken() {
    return localStorage.getItem(AuthService.AUTH_TOKEN);
  }
  public setToken(token: string) {
    localStorage.setItem(AuthService.AUTH_TOKEN, token);
  }
  public deleteToken() {
    localStorage.removeItem(AuthService.AUTH_TOKEN);
  }

  public getTeamPlantMapping() {
    return this.plantsAgainstTeam;
  }

  public getTeams() {
    return this.teams;
  }

  public setTeams(teams) {
    this.teams = teams;
  }

  /**
   *
   * @param teamId
   * @todo need check & balancing
   */
  public getTeamDataById(teamId) {
    return this.plantsAgainstTeam[teamId];
  }

  public setTeamPlantMapping(plantsAgainstTeam) {
    this.plantsAgainstTeam = plantsAgainstTeam;
  }

  public getTeamFromUserId(data): Observable<any> {
    let url = `${env.salesOps.baseUrl}${PARAMS.BUYER_INTERNAL.MODULES.SALESOPS.GET_USER_TEAMS.URL}`;
    return this.apiService.post(url, data);
  }

  public getSalesOpsToken() {
    return env.salesOps.token;
  }

  public getSearchSupplierToken() {
    return env.searchSupplier.token;
  }

  public getMeCatalogueToken() {
    return env.meCatalogue.token;
  }

  public getAccountServiceBaseUrl(){
    return env.accountService.baseUrl
  }

  public getSalesOpsBaseUrl() {
    return env.salesOps.baseUrl
  }

  public getMeCatalogueBaseUrl() {
    return env.meCatalogue.baseUrl
  }

  public getSearchSupplierBaseUrl() {
    return env.searchSupplier.baseUrl
  }

  public getSalesOpsSupplierBaseUrl() {
    return env.salesOpsSupplier.baseUrl;
  }

  public getPlantIdsFromTeam(data): Observable<any> {
    let url = `${env.ems.baseUrl}${PARAMS.BUYER_INTERNAL.MODULES.EMS.GET_TEAM_PLANT_MAPPING.URL}`;
    return this.apiService.post(url, data);
  }

  public getCompanyId() {
    return localStorage.getItem(AuthService.AUTH_COMPANY_ID);
  }
  public setCompanyId(companyId) {
    if (!this.validationService.checkEmptyOrNull(companyId) && companyId !== 'null') {
      localStorage.setItem(AuthService.AUTH_COMPANY_ID, String(companyId));
    }
  }
  public deleteCompanyId() {
    localStorage.removeItem(AuthService.AUTH_COMPANY_ID);
  }

  public getBranchId() {
    return localStorage.getItem(AuthService.AUTH_BRANCH_ID);
  }
  public setBranchId(branchId: number) {
    if (!this.validationService.checkEmptyOrNull(branchId) && !isNaN(branchId)) {
      localStorage.setItem(AuthService.AUTH_BRANCH_ID, String(branchId));
    }
  }
  public deleteBranchId() {
    localStorage.removeItem(AuthService.AUTH_BRANCH_ID);
  }

  public getUserId(): string {
    return localStorage.getItem(AuthService.AUTH_USER_ID);
  }
  public setUserId(userId: string) {
    localStorage.setItem(AuthService.AUTH_USER_ID, userId);
  }

  public getbuildSessionCompletedStatus(): boolean {
    return this.buildSessionCompleted;
  }

  public setbuildSessionCompletedStatus(buildSessionCompleted: boolean) {
    this.buildSessionCompleted = buildSessionCompleted;
  }

  public checkBuildSessionCompleted(): boolean {
    if (this.getbuildSessionCompletedStatus() == false) {
      setTimeout(() => {
        this.checkBuildSessionCompleted();
      }, 1000);
    } else {
      return true;
    }
  }

  public isbuildSessionCompleted(): boolean {
    let result = this.checkBuildSessionCompleted();
    return result;
  }


  public deleteUserId() {
    localStorage.removeItem(AuthService.AUTH_USER_ID);
  }

  public deleteTeams() {
    localStorage.removeItem(AuthService.STORED_TEAMS);
  }

  public deteteGroups() {
    localStorage.removeItem(AuthService.STORED_GROUPS);
  }

  public deleteWarehouses() {
    localStorage.removeItem(AuthService.STORED_WAREHOUSES);
  }

  public deletePlants() {
    localStorage.removeItem(AuthService.STORED_PLANTS);
  }

  public getApplicationId() {
    return localStorage.getItem(AuthService.AUTH_APPLICATION_ID);
  }
  public setApplicationId(applicationId: string) {
    return localStorage.setItem(AuthService.AUTH_APPLICATION_ID, applicationId);
  }

  public getUserName() {
    if (this.sessionObj['userName']) {
      this.userName = this.sessionObj['userName'];
    }
    return this.userName;
  }

  public getUserEmail() {
    let userEmail: string;
    if (this.sessionObj['userEmail']) {
      userEmail = this.sessionObj['userEmail'];
    }
    return userEmail;
  }

  public getAllPlantIdsUsingHistoryId(): any {
    return this.sessionObj["companyToBranch"][this.sessionObj["branchToCompany"][this.lastPlantId]];
  }

  public getGroupsModelForGroupIds(groupIds) {
    let sessionData = this.getSessionObj();
    let groups = sessionData.companyNames;
    let selectedGroups = lodash.pick(groups, groupIds);
    let groupsmodel: Array<GroupModel> = [];
    Object.keys(selectedGroups).forEach(key => {
      groupsmodel.push(new GroupModel(key, selectedGroups[key]));
    });
    return groupsmodel;
  }

  public getPlatsModelForPlantIds(plantIds) {
    let sessionData = this.getSessionObj();
    let plants = sessionData.branchNames;
    let selectedPlants = lodash.pick(plants, plantIds);
    let plantsModel: Array<PlantModel> = [];
    Object.keys(selectedPlants).forEach(key => {
      plantsModel.push(new PlantModel(key, selectedPlants[key]));
    });
    return plantsModel;
  }


  public getGroupsModel() {
    let groups = this.getGroups();
    let groupsmodel: Array<GroupModel> = [];
    Object.keys(groups).forEach(key => {
      groupsmodel.push(new GroupModel(key, groups[key]));
    });
    return groupsmodel;
  }

  public getTeamsModel() {
    let teams = this.getTeams();
    let teamModel: Array<TeamModel> = [];
    Object.keys(teams).forEach(teamId => {
      let team = teams[teamId];
      teamModel.push(new TeamModel(team['teamId'], team['teamName']));
    });
    return teamModel;
  }

  getAllTeams() {
    return this.allTeams;
  }

  public getEnterpriseTeams() {
    return this.allTeams.filter((team: any) => team.idVertical == 4 || team.idVertical == 5).map((team: any) => ({id: team.idTeam, name: team.name, idRegion: team.idRegion}));
  }

  public checkIsSuperTeamAccess() {
    return this.allTeams.length ? this.allTeams[0].isSuperTeamAccess : false;
  }

  public getPlantsModel(groupIds: Array<string>) {
    let plants = [];
    if (groupIds !== null) {
      for (let i = 0; i < groupIds.length; i++) {
        if (this.companyToPlants && this.companyToPlants[groupIds[i]]) {
          const plantArray = this.companyToPlants[groupIds[i]];
          for (const plant of plantArray) {
            plants.push(new PlantModel(plant['plantId'], plant['plantName']));
          }
        }
      }
    }
    return plants;
  }

  public getGroups() {
    return this.groups;
  }

  public setGroups(groups) {
    this.groups = groups;
  }

  public getGroupId(id: number) {
    let groupId: number;
    if (this.sessionObj['branchToCompany']) {
      groupId = this.sessionObj['branchToCompany'][id];
    }
    return groupId;
  }

  public getPlantNameFromId(plantId: string) {
    return this.sessionObj.branchNames[plantId];
  }

  public getCompanyNameFromId(companyId: string) {
    return this.sessionObj.companyNames[companyId];
  }

  public getCompanyIdFromPlantId(plantId: number) {
    return this.sessionObj.branchToCompany[plantId];
  }

  public getPlants(groupIds: Array<string>) {
    let plants: object = {};
    if (groupIds !== null) {
      for (let i = 0; i < groupIds.length; i++) {
        if (this.companyToPlants && this.companyToPlants[groupIds[i]]) {
          const plantArray = this.companyToPlants[groupIds[i]];
          for (const plant of plantArray) {
            Object.assign(plants, { [plant['plantId']]: plant['plantName'] });
          }
        }
      }
      return plants;
    }
    return plants;
  }

  public setCompanyToPlants(companyToPlants) {
    this.companyToPlants = companyToPlants;
  }

  public getAllPlantIds(groups: object) {
    let plantIds: Array<any> = [];
    for (let i = 0; i < Object.keys(groups).length; i++) {
      plantIds = plantIds.concat(Object.keys(this.getPlants(Object.keys(groups))));
    }
    return plantIds
  }

  public getSessionObj(): any {
    return this.sessionObj;
  }

  public isAuthenticated(): boolean {
    if (this.sessionObj && this.sessionObj['userId']) {
      return true;
    }
    return false;
  }

  public isMoglixSuperAdmin(): boolean {
    if (this.sessionObj && this.sessionObj['moglixSuperAdmin']) {
      return this.sessionObj['moglixSuperAdmin'];
    }
    return false;
  }

  public authenticate(data) {
    let url = env.userManagement.baseUrl + PARAMS.USER_MANAGEMENT.MODULES.AUTH.GET_SESSION.URL;
    return this.apiService.post(url, data);
  }

  public haveApprovePoAccess(): boolean {
    if (this.sessionObj && this.sessionObj['approvePoAccess']) {
      return this.sessionObj['approvePoAccess'];
    }
    return false;
  }

  public login(data) {
    let url = env.userManagement.baseUrl + PARAMS.USER_MANAGEMENT.MODULES.AUTH.LOGIN.URL;
    return this.apiService.post(url, data);
  }

  public logout() {
    let url = env.accountService.baseUrl + PARAMS.USER_MANAGEMENT.MODULES.AUTH.LOGOUT.URL;
    return this.apiService.get(url);
  }

  public setSessionObj(sessionObj: any) {
    this.sessionObj = sessionObj;
  }

  public setDesignation(designation: any) {
    this.designation = designation;
  }

  getDesignation() {
    return this.designation;
  }

  public setBusinessDesignation(designation: any) {
    this.businessDesignation = designation;
  }

  getBusinessDesignation() {
    return this.businessDesignation;
  }

  setUserRoles(userRoleList){
    this.userRoleList = userRoleList;
  }

  setOpenPOPlants(openPoPlants) {
    this.openPoPlants = openPoPlants;
    this.openPlatListobs.next(this.openPoPlants);
  }
  setIsManager(isManager){
    this.isManager = isManager;
  }

  getIsManager(){
    return this.isManager;
  }

  setBusinessDesignationType(businessDesignationType: number){
    this.businessDesignationType = Number(businessDesignationType);
  }

  getBusinessDesignationType(): Number {
    return this.businessDesignationType;
  }

  public getGroupsData() {
    let url = env.userManagement.baseUrl + PARAMS.USER_MANAGEMENT.MODULES.SEARCHCOMPANY.URL;
    let data = {
      "searchString": ''
    }
    return this.apiService.post(url, data);
  }

  public onClickcustomerById(_event):Observable<any>{
    let url = env.accountService.baseUrl + PARAMS.USER_MANAGEMENT.MODULES.SEARCHCUSTOMERPLANTBYKEY.URL + '?q='+_event;
    // let dynamicValue = this.headerFormGroup.get('customerPlantId').value;
    return this.apiService.get(url);
   }

  public getUserMetaData() {
    let url = env.accountService.baseUrl + PARAMS.ACCOUNT_SERVICE.MODULES.AUTH.GET_META_DATA.URL;
    return this.apiService.get(url);
  }

  public getUserAuthData() {
    let url = env.accountService.baseUrl + PARAMS.ACCOUNT_SERVICE.MODULES.AUTH.GET_AUTH_DATA.URL;
    return this.apiService.get(url);
  }

  public getUserTeams() {
    let url = env.accountService.baseUrl + PARAMS.ACCOUNT_SERVICE.MODULES.AUTH.GET_USER_TEAMS_BY_USER.URL;
    return this.apiService.get(url);
  }
  public getGroupsDataV1() {
    let url = env.accountService.baseUrl + PARAMS.ACCOUNT_SERVICE.MODULES.AUTH.GET_PLANTS_BY_COMPANY.URL + "?offset=0&limit=999999";
    let data = {
      "searchKey": "",
      "status": "ACTIVE"
    };
    return this.apiService.post(url, data);
  }

  public getPlantIdsFromTeamV1(data): Observable<any> {
    let url = env.accountService.baseUrl + PARAMS.ACCOUNT_SERVICE.MODULES.AUTH.GET_TEAM_PLANT_MAPPINGS.URL + "?offset=0&limit=999999";
    return this.apiService.get(url);
  }

  private getOldUserData(userDataV1) {
    let userData = {
      "successful":true,
      "message":null,
      "status":"200",
      "errors":null,
      "warnings":null,
      "data" :{
        "userData" : {
          "application":1,
          "idDomain":0,
          "userId": userDataV1.data.id,
          "userName":userDataV1.data.firstName + " " + userDataV1.data.lastName,
          "userEmail": userDataV1.data.email,
          "token": this.getToken(),
        }
      }
    }
    return userData;
  }

  public buildSessionV1(): Observable<boolean> {
    return new Observable<boolean>(observer => {
      this.AppProgressBarService.appLoaderStart();
      this.getUserAuthData().subscribe((userDataV1) => {
        if (userDataV1.success) {
          this.getEOCModuleListAccess();
          this.setDesignation(userDataV1.data.designationType);
          this.setBusinessDesignation(userDataV1.data.businessDesignation);
          this.setUserRoles(userDataV1.data.userRoleList);
          this.setUserId(userDataV1.data.id);
          this.setIsManager(userDataV1.data.isManager);
          this.setBusinessDesignationType(Number(userDataV1.data.businessDesignationType));
          this.getAccountModuleList();
          let userData = this.getOldUserData(userDataV1);
          this.getUserMetaData().subscribe((sessionData) => {
            if (sessionData.success) {
              if(!this.validationService.checkEmptyOrNull(sessionData.data) && Object.keys(sessionData.data).length < 1){
                this.alertMessageService.addError(`${MESSAGES.ERROR.PLANTS_DATA_OR_TEAMS_DATA_NOT_FOUND}`).show();
                this.AppProgressBarService.appLoaderStop();
                return null;
              }
              let defaultBranchId = Number(Object.keys(sessionData['data']['branchNames'])[0]);
              this.setBranchId(defaultBranchId);
              if (defaultBranchId) {
                this.setCompanyId(sessionData['data']['branchToCompany'][defaultBranchId]);
              }
              let sessionObj = Object.assign({},
                userData['data']['userData'],
                sessionData['data']);
              this.setSessionObj(sessionObj);
              let teamPlantMapping = {};
              let teamIds = [];
              this.getUserTeams().subscribe(response => {
                if (lodash.has(response, 'data') && response.data.length > 0) {
                  this.allTeams = response.data;
                  response.data.forEach(team => {
                    teamIds.push({
                      teamId: team.idTeam,
                      teamName: team.name,
                      isActive: true,//(team.status==="ACTIVE" ) ? true : false
                    });
                  });
                  this.teams = teamIds;
                  if (teamIds.length > 0) {
                    new Promise<void>((resolve, reject) => {
                      let postData;
                      let plantsIds = [];
                      teamIds.forEach(itemteam => {
                        plantsIds.push(itemteam.teamId);
                      });
                      postData = { teamIds: String(plantsIds) };
                      this.getPlantIdsFromTeamV1(postData).subscribe(response => {
                        if (response.success) {
                          const plantData = response.data;
                          if (lodash.has(response, 'data') && !this.validationService.checkEmptyOrNull(response.data)) {
                            teamIds.forEach(itemteam => {
                              let plantsForTeam = [];
                              let allPlantsInTeam = [];
                              plantData.forEach(item => {
                                if (itemteam.teamId === item.teamId) {
                                  plantsForTeam.push(
                                    String(item.plantId)
                                  )
                                  allPlantsInTeam.push(
                                    {
                                      plantId: item.plantId,
                                      plantName: item.plantName
                                    }
                                  )
                                }
                              });
                              teamPlantMapping[itemteam.teamId] = { teamId: itemteam.teamId, plants: plantsForTeam, plantsDataList: allPlantsInTeam, teamName: itemteam.teamName };
                              if (Object.keys(teamPlantMapping).length === teamIds.length) resolve();
                            });
                            this.teamPlantMappingData = teamPlantMapping;
                          }
                          else {
                            this.alertMessageService.addError(PARAMS.BUYER_EXCEL_CLOUD.ERROR_MESSAGES.SOMETHING_WENT_WRONG).show();
                            this.AppProgressBarService.appLoaderStop();
                          }
                          this.getOpenPoPlantsRespose();
                        } else {
                          this.alertMessageService.addError(PARAMS.BUYER_EXCEL_CLOUD.ERROR_MESSAGES.SOMETHING_WENT_WRONG).show();
                          this.AppProgressBarService.appLoaderStop();
                        }
                      });
                    }).then(() => {
                      this.setbuildSessionCompletedStatus(true);
                      this.AppProgressBarService.appLoaderStop();
                      this.setTeamPlantMapping(teamPlantMapping);
                    })
                  } else {
                    this.setbuildSessionCompletedStatus(true);
                    this.AppProgressBarService.appLoaderStop();
                  }
                } else {
                  this.setbuildSessionCompletedStatus(true);
                  this.AppProgressBarService.appLoaderStop();
                  this.alertMessageService.addError(MESSAGES.ERROR.TEAM_DATA_ERROR).show();
                }
              });
              this.getRegionListSession();
              this.getCustomerPocListSession();
              this.getPaymentTermsSession();
              this.getSupplierListSession();
              this.getBulkPOCustomer();
              this.getGroupsDataV1().subscribe((data) => {
                if (data.success) {
                  let compnies = data.data;
                  this.companies = data.data;
                  let groups = {};
                  for (let i = 0; i < compnies.length; i++) {
                    Object.assign(groups, { [compnies[i]['companyId']]: compnies[i]['companyName'] })
                  }
                  this.setGroups(groups);
                  let companyToPlants = {};
                  for (let i = 0; i < compnies.length; i++) {
                    Object.assign(companyToPlants, { [compnies[i]['companyId']]: compnies[i]['plants'] })
                  }
                  this.setCompanyToPlants(companyToPlants);
                  observer.next(true);
                  observer.complete();
                  return true;
                } else {
                  this.AppProgressBarService.appLoaderStop();
                  observer.next(false);
                  observer.complete();
                  this.router.navigate([PARAMS.BUYER_EXCEL_CLOUD.URLS.LOGIN]);
                  return false;
                }
              });
            } else {
              this.AppProgressBarService.appLoaderStop();
              observer.next(false);
              observer.complete();
              this.router.navigate([PARAMS.BUYER_EXCEL_CLOUD.URLS.LOGIN]);
              return false;
            }
          });
        } else {
          this.AppProgressBarService.appLoaderStop();
          observer.next(false);
          observer.complete();
          this.router.navigate([PARAMS.BUYER_EXCEL_CLOUD.URLS.LOGIN]);
          return false;
        }
      });
    })
  }

  getSupplierListSession() {
    this.poService.getWarehouses().subscribe((res: any) => {
      if((res?.status == 200 || res?.status == 201) && res?.success) {
        this.supplierListObs.next(res?.data);
        this.setSupplierList(res?.data);
        this.setIsGetSupplierListApiCallingCompleted(true);
      } else {
        this.setIsGetSupplierListApiCallingCompleted(true);
      }
    }, (err: any) => {
      this.setIsGetSupplierListApiCallingCompleted(true);
    })
  }

  setIsGetSupplierListApiCallingCompleted(isGetSupplierListApiCallingCompleted: boolean) {
    this.isGetSupplierListApiCallingCompleted = isGetSupplierListApiCallingCompleted;
  }

  getIsGetSupplierListApiCallingCompleted(): boolean {
    return this.isGetSupplierListApiCallingCompleted;
  }

  setSupplierList(supplierList: Array<string>) {
    this.supplierList = supplierList;
  }

  getSupplierList(): Array<string> {
    return this.supplierList;
  }

  getPaymentTermsSession() {
    this.commonService.getPaymentTerms().subscribe((res: any) => {
      if((res?.status == 200 || res?.status == 201) && res?.success) {
        this.setPaymentTermsList(res?.data);
        this.setIsGetPaymentTermsApiCallingCompleted(true);
      } else {
        this.setIsGetPaymentTermsApiCallingCompleted(true);
      }
    }, (err: any) => {
      this.setIsGetPaymentTermsApiCallingCompleted(true);
    })
  }

  setIsGetPaymentTermsApiCallingCompleted(isGetPaymentTermsApiCallingCompleted: boolean) {
    this.isGetPaymentTermsApiCallingCompleted = isGetPaymentTermsApiCallingCompleted;
  }

  getIsGetPaymentTermsApiCallingCompleted(): boolean {
    return this.isGetPaymentTermsApiCallingCompleted;
  }

  setPaymentTermsList(paymentTermsList: Array<string>) {
    this.paymentTermsList = paymentTermsList;
  }

  getPaymentTermsList(): Array<string> {
    return this.paymentTermsList;
  }

  getCustomerPocListSession() {
    this.poService.getCustomerPocList().subscribe((res: any) => {
      if(res?.status == 200 && res?.success) {
        this.customerPocListObs.next(res?.data);
        this.setCustomerPocList(res?.data);
        this.setIsGetCustomerPocApiCallingCompleted(true);
      } else {
        this.setIsGetCustomerPocApiCallingCompleted(true);
      }
    }, (err: any) => {
      this.setIsGetCustomerPocApiCallingCompleted(true);
    })
  }

  setIsGetCustomerPocApiCallingCompleted(isGetCustomerPocApiCallingCompleted: boolean) {
    this.isGetCustomerPocApiCallingCompleted = isGetCustomerPocApiCallingCompleted;
  }

  getIsGetCustomerPocApiCallingCompleted(): boolean {
    return this.isGetCustomerPocApiCallingCompleted;
  }

  setCustomerPocList(customerPocList: Array<string>) {
    this.customerPocList = customerPocList;
  }

  getCustomerPocList(): Array<string> {
    return this.customerPocList;
  }

  getRegionListSession() {
    this.teamService.getRegions().subscribe((res: any) => {
      if (res?.status == 200 && res?.success) {
        this.setRegionList(res?.data);
        this.setIsGetRegionsApiCallingCompleted(true);
      } else {
        this.setIsGetRegionsApiCallingCompleted(true);
      }
    }, (err: any) => {
      this.setIsGetRegionsApiCallingCompleted(true);
    })
  }

  setIsGetRegionsApiCallingCompleted(isGetRegionsApiCallingCompleted: boolean) {
    this.isGetRegionsApiCallingCompleted = isGetRegionsApiCallingCompleted;
  }

  getIsGetRegionsApiCallingCompleted(): boolean {
    return this.isGetRegionsApiCallingCompleted;
  }

  setRegionList(regionList: Array<any>) {
    this.regionList = regionList;
  }

  getRegionList(): Array<any> {
    return this.regionList;
  }

  getBulkPOCustomer() {
    this.bulkPOCustomer().subscribe((res: any) => {
      if (res?.code == 200 && res?.success) {
        this.bulkPOCustomerList = res?.data?.value;
      }
    })
  }

  getBulkPOCsutomerList() {
    return this.bulkPOCustomerList;
  }

  bulkPOCustomer() {
    let url = `${env.mceConfiguration.baseUrl}${PARAMS.BUYER_INTERNAL.MODULES.RFQ.BULK_PO_CUSTOMER_CONF.URL}`;
    return this.apiService.get(url);
  }

  public getUserModuleAccess() {
    let url = env.accountService.baseUrl + PARAMS.ACCOUNT_SERVICE.MODULES.AUTH.GET_MODULE_ACCESS_BY_USER.URL + '?mainModuleName=Accounts';
    return this.apiService.get(url);
  }

  getEOCModuleList() {
    let url = env.accountService.baseUrl + PARAMS.ACCOUNT_SERVICE.MODULES.AUTH.GET_MODULE_ACCESS_BY_USER.URL + '?mainModuleName=EOC';
    return this.apiService.get(url);
  }

  public checkSubmoduleAccess(param) {
    let url = env.accountService.baseUrl + PARAMS.ACCOUNT_SERVICE.MODULES.AUTH.GET_SUBMODULE_ACCESS_BY_USER.URL + '?moduleName=' + param;
    return this.apiService.get(url);
  }

  getOpenPoPlantsRespose() {
    this.getOpenPoPlants().subscribe((res) => {
      if (res.data && res.data.value && res.data.value.length > 0) {
        let list = res.data.value.split(',');
        this.setOpenPOPlants(list);
      }
    })
  }

  getOpenPoPlants() {
    let url = env.mceConfiguration.baseUrl + "config/get-by-key/open-po-plantIds";
    return this.apiService.get(url);
  }
  
  public checkUrlAccess(route): Observable<boolean> | boolean {
    return new Observable<boolean>((observer) => {
      this.checkSubmoduleAccess(route).subscribe({
        next: res => {
          if (res.success) {
            if (res.data.isAuthorized) {
              this.setAccessObject(res.data);
              observer.next(true);
              observer.complete();
              return true;
            } else {
              observer.next(false);
              observer.complete();
              return false;
            }
          }
        },
        error: (error) => {
          this.router.navigateByUrl("");
        }
      });
    })
  }

  public checkUrlAccessNonAccounts(route): Observable<boolean> | boolean {
    return new Observable<boolean>((observer) => {
      this.checkSubmoduleAccess(route).subscribe({
        next: res => {
          if (res.success) {
            if (res.data.isWriteConfigured) {
              this.setAccessObject(res.data);
              observer.next(true);
              observer.complete();
              return true;
            } else {
              this.alertMessageService.addError("Don`t Have Access To Do This Operation").show();
              observer.next(false);
              observer.complete();
              return false;
            }
          }
        },
        error: (error) => {
          this.router.navigateByUrl(PARAMS.BUYER_EXCEL_CLOUD.URLS.PO_LIST);
        }
      });
    })
  }


  public setAccessObject(data) {
    this.accessObj = data;
  }

  public getAccountModuleList() {
    this.getUserModuleAccess().subscribe((response) => {
      if (response.success) {
        if (response.data && response.data.length > 0) {
          this.moduleList = response.data;
          this.moduleLIstChange.next(this.moduleList);
        }
      }
    },
    (error)=> this.alertMessageService.addError("Facing an error message? Contact our Tech Support Team for assistance").show()
  );

  }

  getEOCModuleListAccess() {
    this.getEOCModuleList().subscribe((res: any) => {
      if (res?.status == 200 && res?.success) {
        this.eocModuleAccessRoleList.next(res?.data);
      } else {
        this.alertMessageService.addError("Facing an error message? Contact our Tech Support Team for assistance").show();
      }
    }, (err: any) => {
      this.alertMessageService.addError("Facing an error message? Contact our Tech Support Team for assistance").show();
    })
  }

  public buildSession(): Observable<boolean> {
    let postData = { "dataType": 1 };
    return new Observable<boolean>(observer => {
      this.AppProgressBarService.appLoaderStart();
      this.authenticate(postData).subscribe((userData) => {
        if (userData.successful) {
          postData = { "dataType": 2 };
          this.authenticate(postData).subscribe((sessionData) => {
            if (sessionData.successful) {
              let defaultBranchId = Number(Object.keys(sessionData['data']['companyData']['branchNames'])[0]);
              this.setBranchId(defaultBranchId);
              if (defaultBranchId) {
                this.setCompanyId(sessionData['data']['companyData']['branchToCompany'][defaultBranchId]);
              }
              let branchAccessData = {
                "idUser": userData['data']['userData']['userId'],
                "idBranch": this.getBranchId(),
              };
              let sessionObj = Object.assign({},
                userData['data']['userData'],
                sessionData['data']['companyData']);
              this.setSessionObj(sessionObj);
              let teamPostData = {
                "userEmail": String(this.getUserEmail())
              }
              let teamPlantMapping = {};
              let teamIds = [];
              this.getTeamFromUserId(teamPostData).subscribe(response => {
                if (lodash.has(response, 'data') && response.data.length > 0) {
                  response.data.forEach(team => {
                    teamIds.push({
                      teamId: team.teamId,
                      teamName: team.teamName,
                      isActive: team.isActive
                    });
                  });
                  this.teams = teamIds;
                  if (teamIds.length > 0) {
                    new Promise<void>((resolve, reject) => {
                      let postData;
                      let plantsIds = [];
                      teamIds.forEach(itemteam => {
                        plantsIds.push(itemteam.teamId);
                      });
                      postData = { teamIds: String(plantsIds) };
                      this.getPlantIdsFromTeam(postData).subscribe(response => {
                        if (response.code === 200) {
                          const plantData = response.data;
                          if (lodash.has(response, 'data') && !this.validationService.checkEmptyOrNull(response.data)) {
                            teamIds.forEach(itemteam => {
                              let plantsForTeam = [];
                              let allPlantsInTeam = [];
                              plantData.forEach(item => {
                                if (itemteam.teamId === item.teamId) {
                                  plantsForTeam.push(
                                    String(item.plantId)
                                  )
                                  allPlantsInTeam.push(
                                    {
                                      plantId: item.plantId,
                                      plantName: item.plantName
                                    }
                                  )
                                }
                              });
                              teamPlantMapping[itemteam.teamId] = { teamId: itemteam.teamId, plants: plantsForTeam, plantsDataList: allPlantsInTeam, teamName: itemteam.teamName };
                              if (Object.keys(teamPlantMapping).length === teamIds.length) resolve();
                            });
                            this.teamPlantMappingData = teamPlantMapping;
                          }
                          else {
                            this.alertMessageService.addError(PARAMS.BUYER_EXCEL_CLOUD.ERROR_MESSAGES.SOMETHING_WENT_WRONG).show();
                            this.AppProgressBarService.appLoaderStop();
                          }
                        } else {
                          this.alertMessageService.addError(PARAMS.BUYER_EXCEL_CLOUD.ERROR_MESSAGES.SOMETHING_WENT_WRONG).show();
                          this.AppProgressBarService.appLoaderStop();
                        }
                      });
                    }).then(() => {
                      this.setbuildSessionCompletedStatus(true);
                      this.AppProgressBarService.appLoaderStop();
                      this.setTeamPlantMapping(teamPlantMapping);
                    })
                  } else {
                    this.setbuildSessionCompletedStatus(true);
                    this.AppProgressBarService.appLoaderStop();
                  }
                } else {
                  this.setbuildSessionCompletedStatus(true);
                  this.AppProgressBarService.appLoaderStop();
                  this.alertMessageService.addError(MESSAGES.ERROR.TEAM_DATA_ERROR).show();
                }
              });
              this.getGroupsData().subscribe((data) => {
                if (data.successful) {
                  let compnies = data.data;
                  this.companies = data.data;
                  let groups = {};
                  for (let i = 0; i < compnies.length; i++) {
                    Object.assign(groups, { [compnies[i]['company']]: compnies[i]['companyname'] })
                  }
                  this.setGroups(groups);
                  let companyToPlants = {};
                  for (let i = 0; i < compnies.length; i++) {
                    Object.assign(companyToPlants, { [compnies[i]['company']]: compnies[i]['plants'] })
                  }
                  this.setCompanyToPlants(companyToPlants);
                  observer.next(true);
                  observer.complete();
                  return true;
                } else {
                  this.AppProgressBarService.appLoaderStop();
                  observer.next(false);
                  observer.complete();
                  this.router.navigate([PARAMS.BUYER_EXCEL_CLOUD.URLS.LOGIN]);
                  return false;
                }
              });
            } else {
              this.AppProgressBarService.appLoaderStop();
              observer.next(false);
              observer.complete();
              this.router.navigate([PARAMS.BUYER_EXCEL_CLOUD.URLS.LOGIN]);
              return false;
            }
          });
        } else {
          this.AppProgressBarService.appLoaderStop();
          observer.next(false);
          observer.complete();
          this.router.navigate([PARAMS.BUYER_EXCEL_CLOUD.URLS.LOGIN]);
          return false;
        }
      });
    })
  }

  handleBuildSessionError(data) {
    this.deleteToken();
    this.deleteBranchId();
    this.deleteUserId();
    this.alertMessageService.addError(data['message']).show();
  }

  public getAllPlants() {
    let plants: Array<any> = [];
    if (this.companies != null || this.companies != undefined) {
      this.companies.forEach(company => {
        plants = plants.concat(company.plants);
      });
    }
    return plants;
  }

  public getTeamsData(){
    let teamdata = {
      teamPlantMappingdata: this.teamPlantMappingData,
      teams: this.teams
    }
    return teamdata;
  }

  public getWmsBaseUrl() {
    return env.wms.baseUrl
  }

  public getWmsToken() {
    return env.wms.token;
  }

  public getEmsBaseUrl() {
    return env.ems.baseUrl;
  }
  
  // getSalesOpsSupplierToken() {
  //   return env.salesOpsSupplier.token;
  // }

  public getMceNotificationBaseUrl() {
    return env.mceNotification.baseUrl;
  }

  public getPlatformBaseUrl() {
    return env.platform.baseUrl;
  }

  public getTsFsBaseUrl() {
    return env.freshService.baseUrl;
  }

  public getFinanceBaseUrl() {
    return env.finance.baseUrl;
  }

  public getTsFsToken() {
    return env.freshService.token;
  }

  public getAIBaseUrl() {
    return env.genAI.baseUrl;
  }
  
  public getAnalyticsVendorApiBaseUrl() {
    return env.vendor.baseUrl;
  }
  
  /**
     * Check User is already login or not.
     *
     * @author Suchita Dabhade <suchita.angularminds@moglix.com>
     */
  public isUserAuthenticated(){
    return localStorage.getItem(AuthService.AUTH_TOKEN) && localStorage.getItem(AuthService.AUTH_USER_ID)
  }

  getTeamByplantId(plantId){
    let url = env.accountService.baseUrl + PARAMS.ACCOUNT_SERVICE.MODULES.TEAMS.GET_TEAM_BY_PLANTID.URL + plantId;
    return this.apiService.get(url);
  }
}
