export const MESSAGES = {
  ERROR: {
    SOMETHING_WENT_WRONG: 'Something Went Wrong',
    TRY_AGAIN: 'Something went wrong. Please retry again.',
    DATA_CHECK_SOMETHING_WENT_WRONG: 'Something Went Wrong Please Check The Data',
    TEAM_DATA_ERROR: 'Something Went Wrong, Unable to Fetch Teams Data',
    LOGIN_FAILED: 'Login failed. Please retry again.',
    TRY_AGAIN_WITH_REFRESH: 'Something went wrong. Please retry by refreshing your page.',
    MAX_ATTACHMENT_LIMIT_EXCEED: 'Maximum file size limit exceeded.',
    ATTACHMENT_FORMAT_ERROR: 'Please Choose Valid File Format',
    MAX_3_FILE_ATTACHMENT_ERROR: 'MAXIMUM 3 Files Can Be Uploaded',
    MAX_5_FILE_ATTACHMENT_ERROR: 'MAXIMUM 5 Files Can Be Uploaded',
    CANNOT_DELETE_LAST_ONE_ITEM: "Cannot Delete Last One Item",
    NO_RECORDS: "No Records To Show",
    WAREHOUSEDATA_ERROR: "Unable To Get Warehouse Data",
    PLANTS_DATA_OR_TEAMS_DATA_NOT_FOUND: "Plants Data Or Teams Data Not Found, Unable To Login",
    COLUMN_FILTER_SAVED_NO_DATA: "No Data Available for Selected Column Filters! Reset the filters to view data",
    INVALID_FUTURE_CPO_DATE: "Error: The CPO date cannot be a future date.",
    SA: {
      RELEASE_SA_MORE_DEMAND_QTY_ERROR: "Demand Quantities and Consumed Quantities Cannot Be More Than Approved Quantites",
      CLOSED_SA_PO_CREATE_ERROR: 'You cannot create schedule on expired/closed SA!'
    },
    RFQ: {
      MSN_SUGGESTION_ERROR: "No Suggestions Found For The Selected Item",
      MANDATORY_FIELD_RFQ_QUOTE: "Please Enter Mandatory Fields To Quote",
      TEAM_SELECT_FROM_LIST: "Please Select Team From List",
      TEAM_ID_FETCH: "Team ID Missed, Please Reselect Teams from the list",
      UNABLE_TO_CANCEL: "Unable To Cancel Some RFQ Item(s) with Quoted, Lost or Won Status",
      CUSTOMER_POC_MOGLIX_POC_MANDATORY: "Please Enter Moglix POC and Customer POC",
      MOGLIX_POC_MANDATORY: "Please Enter Sourcing POC",
      CUSTOMER_POC_MANDATORY: "Please Enter Customer POC",
      QUOTE_UNSAVED_DATA: "Please Save Entered Data To Quote",
      ITEMS_SHOULD_NOT_MORE_THAN_100: "Items Cannot Not Exceed to 100",
      ADD_ONE_ITEM_ATLEAST: "Please Add Atleast One Item To Proceed.",
      VALID_MOGLIX_EMAIL: "Please Enter Valid Moglix Email Id For Sourcing / Customer POC",
      INVALID_SOURCER_POC: "Please Enter Valid Sourcing POC",
      INVALID_CUSTOMER_POC: "Please Enter Valid Customer POC",
      VALID_CATALOG_MOGLIX_EMAIL: "Please Enter Valid Moglix Email Id For Catalog POC Email ID",
      MANDATORY_FIELD_REASON_FOR_LOSS: "Please Enter Reason For RFQ Loss",
      QUOTE_VALIDITY_REQUIRED: "Quote Validity Is Required.",
      EMAIL_ID_REQUIRED: "Please Provide At least One Email ID to Quote",
      QUOTE_VALIDITY_INVALID: "Invalid Quote Validity,Please Enter Valid One",
      MANDATORY_FIELD_TO_STATUS_QUOTE: "Please Enter Lead Time to Customer (days), Final TP, Final SP, Payment Terms, Enquiry Type",
      SUPPLIER_SUGGESTION_ERROR: "Error in Supplier Suggestion, please try again",
      SUPPLIER_EMAIL_REQUIRED_ERROR: "To Supplier Email ID Is Required ",
      //REGISTER_SUPPLIER_ERROR:"Only Registered Supplier Email Avaliable On Autosearch Is Accepted At To Supplier",
      INVALID_EMAIL_ID: "Enter Valid Email ID",
      INVALID_CCEMAIL_ERROR: "Please Provide Valid CC Emails(comma seperated)",
      UPDATE_RFQITEM_AND_QUOTE_STATUS_ERROR: "Updation failed.Please try again.",
      COMMENTS_REQUIRED_ERROR: "Comments Is Required.",
      TERMS_CONDITIONS_REQUIRED_ERROR: "Terms and Conditions Is Required.",
      SUBJECTLINE_MAX_LENGTH_ERROR : "Maximum 170 Characters Allowed On Subject Line For Mail",
      SUPPLIER_FLOW: {
        ADD_MANDATORY_QUOTE_FIELDS: "Please Add Mandatory (*) marked Fields",
        NUMBER_TIME_VALIDATION: "List Price, Discount, Net Price/Unit, Lead Time, Validity, HSN Should Be Integer and Non Negative",
        SELECT_ONE_ITEM_ATLEAST: "Please Select One Item Atleast",
        UPLOAD_ITEMS_LENGTH_CHECK: "Upload Items list Description Should Be Match Items In The List and Total Should Be Same" ,
        DECIMAL_NO_ERROR: "Lead Time Should Be Non Decimal Number",
        INVALID_USER_TO_QUOTE: "You Are Not Allowed To Submit Quote",
      },
      SUPPLIER_SUGGESTION: {
        NO_DATA_TO_ADD_ERFQ: "Please add Suppliers for ERFQ"
      },
      SELECT_ONE_RFQ_ITEM_ATLEAST:"Please Select At Least One Rfq Item",
      SELECT_ONE_SUPPLIER_ATLEAST:"Please Select At Least One Supplier",
      MAXIMUM_50_RFQ_ITEMS:"Maximum 50 RFQ Items Can Be Selected At a Time",
      MAXIMUM_100_RFQ_ITEMS:"Maximum 100 RFQ Items Can Be Selected At a Time",
      MAXIMUM_20_CC_EMAIL_IDS: "Maximum 20 CC Email Ids Can Be Enter",
      MANDATORY_FIELDS_ERROR: "Please Fill All Mandatory Fields",
      XLXS_FORMAT_ERROR: "Invalid File Format,Upload File Should Be .xls Format",
      INTEGER_NONNEGATIVE_ERROR: "Should Be Integer And Non Negative",
      L1_CATEGORY_ERROR: "Please Fill L1 Category Data First ",
      GM_APPROVAL: {
        REJECT_REMARKS_ERROR: "Remarks Is Required"
      },
      NULL_UPDATION_ERROR: "Lead Time, Final SP, Final TP Can Not Be Updated As Null",
      PO_WON_STATUS_UPDATE_FINAL_SP_FINAL_TP_NULL_ERROR: "Can Not Select Status 'po won' If Final Tp Or Final Sp Is Null",
      MANUAL_UPDATION_RFQ_STATUS_QUOTE_ACCEPTED_QUOTE_REJECTED_ERROR: "Manual Updatation Of status 'quote accepted' Or 'quote rejected' Is not allowed on EOC",
      SUPPLIER_SUGGEST_MANDATORY_FIELDS: "Plant and CPN Details are Mandatory For Supplier Suggestions",
      INVALID_RFQ_ID: "RFQ id entered is either PO Won or Invalid!",
      COMPANY_ID_MISSING: "Complete Details are not there with given RFQ Id so unable to populate details",
      SP_CALCULATION_GM_REMOVE: "Kindly remove Expected GM% details SP/Unit can be calculated EITHER on Final TP/Unit & Expected GM% OR on LP to Customer & Discount",
      INVALID_STATUS_VALUE_ERROR: "Please select Rfq status from Dropdown",
      INVALID_FINAL_SP_FOR_ARC: "SP should be equal or greater than ARC to process this item!",
      INVALID_GST_PERCENTAGE_ERROR: "Please select GST % from Dropdown",
      GOVERNED_MSN_CHANGE_ENQUIRY: "We have changed the enquiry type to RFQ as the MSN mapped with this RFQ item is a governed MSN and it cannot be CRMM.",
      ENQUIRY_TYPE_MANDATORY_FOR_CM_AND_QUOTE: "Enquiry Type is missing. Please fill to proceed further!"
    },
    COMMON: {
      TAX_VALIDATION: "Tax Should Be Integer And Non Negative And Less Than 100.",
      OMT_STATUS_ERROR: "Please Fill OMT Staus Data First ",
      CDD2_VALIDATION_ABOVE_TWO_YEAR_ERROR: "Fields cannot be updated as the CDD2 date is either earlier than today's date or more than two years after the PO creation date.",
      CDD2_VALIDATION_BETWEEN_ONE_TO_TWO_YEAR_ERROR: "You do not have access to update CDD2 greater than 1 yr from the creation date."
    },
    PO_ERROR: {
      HSN_CODE_LENGTH_ERROR: "HSN CODE should be 6 Digit or 8 Digit",
      NON_INDIAN_HSN_CODE_LENGTH_ERROR: "HSN CODE should be 6 to 8 Digit",
      HSN_CODE_LENGTH_ERROR_NEW_ADDED_ITEMS: "HSN CODE should be 6 Digit or 8 Digit for Newly added Items",
      INVALID_HSN_WITH_CPN: "Invalid HSN OR HSN Does Not Exist In Some CPN`s",
      //MSN_EMPTY_ERROR: "Unable To Display Supplier List MSN is Empty",
      MSN_EMPTY_ERROR: "Unable To Display Data As MSN Is Empty",
      SELECT_TAX_FROM_LIST: "Please Select Tax Value From The List",
      RFQ_TYPE_MANDATORY: "Rfq ID`s are Mandatory for RFQ Type",
      RFQ_ITEM_ID_MANDATORY: "RFQ Item Id Is Mandatory",
      CRMM_NOT_ALLOWED_FOR_PLANT: "This plant is not an approved CRMM plant so punching of orders with type CRMM not allowed!",
      ARC_TYPE_RFQ_NOT_REQUIRED: "RFQ Item ID Not Required For Arc / CRMM Type Or Please Select ARC/RFQ Type",
      ERROR_ARCCONFIGCHECK_CPN_VALIDATION: "CPN id's or brands are not present as a valid ARC configuration. Please select another PO Type in Is ARC/RFQ dropdown",
      ERROR_PRICE_DIFF_CPN_VALIDATION: "CPO punching is not allowed for ARC net price items as price difference (SP < ARC) > 10%",
      GET_PAYMENTERMS_ERROR: "Unable To Get Payment Terms",
      RFQ_SUGGESTION_NULL_ERROR: "No Suggestions Found For The Selected Item",
      RFQ_SUGGESTION_REQUESTDATA_ERROR: "Please Select RFQ In ARC/RFQ Dropdown For RFQ Suggestions",
      VMI_CHECK_ENABLED_CUSTOMER_ID_MANDATORY_ERROR: "Please Select Valid Customer Plant From The List for VMI Type",
      VMI_CHECK_ENABLED_VMI_TYPE_MANDATORY_ERROR: "VMI Type Value Is Mandatory",
      PO_RECEIVE_DATE_GREATER_THAN_CURRENT_DATE_ERROR: "PO Receive Date Cannot Be Greater Than Current Date",
      PO_RECEIVE_DATE_LESSER_THAN_CUSTOMER_PO_DATE_ERROR: "PO Receive Date Cannot Be Lesser Than Customer PO Date",
      SUPPLIER_GSTN_NOT_FOUND_ERROR: "Supplier GSTN not found. PO cannot be punched.",
      POC_CONTACT_INVALID_ERROR: "Purchaser Mobile No. Is Invalid",
      LINE_ITEMS_DETAILS_CHECKBOX_ERROR: "Please confirm by clicking the checkbox, that the details are verified",
      CLOSE_PO_ITEMS_ERROR : {
        CLOSING_REASON_REQUIRED: "Closing Reason Is Null For PO Ids : ",
        SELECT_ITEMS_ERROR: "Please Select Items First",
        WRONG_ITEM_SELECTION_ERROR: "Please Only Select Items For Closure Which Are Not Invoiced / Closed / Empty",
        ITEMS_NOT_CLOSED_ERROR: "Some Items Are Not Closed",
        MAX_30_ITEMS_SELECTION_ERROR: "Maximum 30 Items Can Be Selected"
      },
      JIT_NOT_ALLOWED_ERROR: "JIT Orders Cannot Be Placed Against Plant Id:",
      GM_APPROVED_RFQITEMID_REQUIRED : "Please Enter CM Approved RFQ Item Id For CRMM ",
      MARK_RELEASE_PUSH_TO_SOP_ERRORS: {
        BRAND_IS_REQUIRED_ERROR:" Brand Is Required Field",
        VALID_DATE_REQUIRED_ERROR: "Please enter valid date.",
        CUSTOMER_POC_REQUIRED_ERROR: "Please Enter Customer POC",
        SOURECER_EMAIL_ID_REQUIRED_ERROR:"Please Enter Sourcer Email Id"
      },
      UPLOAD_PROOF_ORDER_REQUIRED_ERROR: "Please upload at least one document.",
      CONSIGNEE_CONTACT_INVALID_ERROR: "Consignee Receiver Contact Is Invalid",
      CONSIGEE_PATTERN_ERROR: "Consignee Receiver Name/Email name has invalid(Spec.) characters.",
      REPEAT_PO_DETECTED: "Few items tagged as Direct PO are actually Repeat POs and we have mapped the original item refs against them",
      //DIRECT_PO_SELECT_ERROR: "Is Direct PO  field is mandatory for  CRMM  CPO Item Type"
      GOVERNED_MSN_CHANGE_ITEM_TYPE: "We have changed the CPO item type to RFQ as the MSN mapped with this CPO item is a governed MSN and it cannot be CRMM",
      SELECT_EXTEND_DATE_ERROR: "Please select PO expiry Date to proceed",
      NON_PAAS_PLANT_TO_PLANT_ERROR :"This plant is not an PAAS plant so punching of orders with type PAAS not allowed!",
      PAAS_PLANT_ACCESS_ERROR :"You don't have access to role required for PAAS transaction",
      SELECT_CATEGORY:"Please select 'Category'"
    },
    ITEM_ERROR: {
      ITEM_SHEET_DATE_LIMIT_ERROR: "Select Date Range upto 3 Months",
      ROUTING_CONFIGURATION_NOT_YES: "CRMM Configuration is Not Yes In Any Of The Selected Items",
      LIST_PRICE_NEGATIVE_ERROR: "List Price Can Not Be Negative",
      LIST_PRICE_FINAL_TP_BLANK_ERROR: "List Price, Final TP Can Not Be Updated As Blank",
      DISCOUNT_NEGATIVE_ERROR: "Discount Can Not Be Negative",
      NOT_DECIMAL_VALUE: "Should Not Be Decimal",
      CREDEIT_DAYS_NEGATIVE_ERROR: "Credit Days Can Not Be Negative",
      CANCEL_ITEMS: "Please select atleast one item which isn't partially / fully invoiced, to proceed item Cancel / Cancel and Clone",
      INVALID_RFQ_ID_ERROR: "Invalid RFQ Id`s",
      CPN_ARC_ERROR: "CPN`s are not mapped to ARC",
      DOWNLOAD_DATE_RANGE_LIMIT_ERROR: "Please select 3 Months Data Only To Download"
    },   
    ACCOUNT_SERVICE_ERROR: {
      WAREHOUSE: {
        SUBSIDIARIES_FETCH_ERROR: "Error in fetching Subsidiaries",
        WAREHOUSE_FETCH_ERROR: "Error in fetching Warehouse List"
      }
    },
    
    VENDOR_SERVICE:{
      DETAILS:{
        DELETE_FILE:"Deleting File"
      }
    }
  },
  SUCCESS: {
    ITEM_SUCCESS: {
      ADDED_ROUTING_CONFIGURE_SUCCESS: "Added CRMM Configuration Successfully For The Shown Items",
      CLOSE_PO_ITEMS_SUCCESS: "Selected PO Items Closed Successfully"
    },
    RFQ_SUCCESS: {
      CREATED_RFQ_ITEMS_SUCCESS: "Created RFQ Items Successfully",
      QUOTE_SAVE: "Quote Saved Successfully.",
      RFQ_ITEM_UPDATED:"RFQ Item Updated Successfully",
      ADD_SUPPLIER_TO_QUOTE: "Sent Supplier Quote successfully",
      GM_APPROVAL: {
        APPROVED: "Request Approved",
        REJECTED: "Request Rejected"
      }
    },
    ACCOUNT_SERVICE: {
      PLANT: {
        ADDED_PAYMENT_TERMS_SUCCESSFULLY: "Added Payment Terms Successfully",
        ADDED_WAREHOUSE_MAPPING_SUCCESSFULLY: "Added Warehouse Mapping Successfully"
      }
    },
    
    VENDOR_SERVICE:{
      DETAILS:{
        SUCCESS_FILE_UPLOAD:"Uploaded the file successfully",
        REQUIRED_FIELD:"Fill the required Fileds",
        DATA_INSERTED_IN_DB:"Data inserted to DB"
      }
    }
  }
}