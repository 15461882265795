export const APP_ROUTES = {
    PO: [
        { title: "Listing", icon: "list_alt", link: ['/po/list'], ga: 'CPO Listing'},
        { title: "CPO Details", icon: "list", link: ['/po/items-sheet'], ga: 'CPO Details' },
        { title: "Punch CPO", icon: "list", link: ['/po/punch/header'], queryParams: {poAction: 'createPO'}, ga: 'Punch CPO' },
        { title: "Pending CM* Approval", icon: "rule", link: ['/po/po-approval-sheet'], ga: 'CPO Pending CM* Approval'},
        { title: "Bulk Punching", hasSubMenu: true,
            subMenus: [
                { title: "CPO-SPO Creation", link: ['/po/paas-cpo-spo-automation'], ga: 'CPO-SPO Creation', isAccessBased: true },
                { title: "CPO-Invoice Creation", link: ['/po/bulk-upload/bulk-po'], ga: 'CPO-Invoice Creation', isAccessBased: false },
            ]
        },
        { title: "Pending Approval", icon: "playlist_add_check", link: ['/po/approval-list'], ga: 'Pending Approval' },
        { title: "Saved as Draft", icon: "library_books", link: ['/po/po-draft'], ga: 'CPO Save As Draft' },
    ],
    SCHEDULE_AGREEMENT:[
        { title: "Listing", icon: "notes", link: ['/sa/list'], ga: 'SA Listing' },
        { title: "SA Details", icon: "chrome_reader_mode", link: ['/sa/sa-items-sheet'], ga: 'SA Details' },
        { title: "Punch SA", icon: "chrome_reader_mode", link: ['/sa/punch/header'], queryParams: {saAction: 'createSA'}, ga: 'Punch SA' },
    ],
    ITEM_SHEET: [
        { title: "Catalog View", icon: "assignment", link: ['/po/catalog-items-sheet'], ga: 'Item Sheet Catalog View' },
        { title: "Suppliers View", icon: "recent_actors", link: ['/po/suppliers-items-sheet'], ga: 'Item Sheet Supplier View' },
        { title: "OMT View", icon: "horizontal_split", link: ['/po/omt-items-sheet'], ga: 'Item Sheet Omt View' },
        { title: "Serviceability", icon: "list", link: ['/serviceability/details'], ga: 'Serviceability' },
    ],
    ACCOUNT_SERVICES: [
        { title: "Functional Areas", icon: "list", link: ['/account-services/functional-areas'], ga: 'Account Functional Areas' },
        { title: "Modules", icon: "list", link: ['/account-services/modules'], ga: 'Account Modules' },
        { title: "Companies", icon: "list", link: ['/account-services/companies'], ga: 'Account Companies' },
        { title: "Plants", icon: "list", link: ['/account-services/plants'], ga: 'Account Plants' },
        { title: "Warehouses", icon: "list", link: ['/account-services/warehouses'], ga: 'Account Warehouses' },
        { title: "Teams", icon: "list", link: ['/account-services/teams'], ga: 'Account Teams' },
        { title: "Roles", icon: "list", link: ['/account-services/roles'], ga: 'Account Roles' },
        { title: "Users", icon: "list", link: ['/account-services/users'], ga: 'Account Users' },
    ],
    RFQ: [
        { title: "Enquiry Details", icon: "toc", link: ['/pre-po/rfq-sheet'], ga: 'Enquiry Details' },
        { title: "Punch Enquiry", icon: "library_books", link: ['/pre-po/punch-prepo/items'], ga: 'Punch Enquiry' },
        { title: "Pending CM* Approval", icon: "rule", link: ['/pre-po/gm-approval/gm-approval-sheet'], ga: 'Pending CM* Approval'},
    ],
    ARC: [
        { title: "Summary", icon: "list", link: ['/arc/arc-summary'], ga: 'ARC Summary' },
        { title: "Listing", icon: "article", link: ['/arc/arc-list'], ga: 'ARC Listing' },
    ],
    CATALOG: [
        { title: "Listing", icon: "list", link: ['/catalog/list'], ga: 'Catalog Listing' },
        { title: "Create CPN", icon: "list", link: ['/catalog/product'], queryParams: {productAction: 'createProduct'}, ga: 'Create CPN' },
    ],
    REPORTS: [
        { title: "Enquiry Pendency", icon: "list", link: ['/reports/rfq-pendency'], ga: "Enquiry Pendency" },
        { title: "ARC Performance", icon: "list", link: ['/reports/arc-performance'], ga: 'ARC Performance' }
    ],
    PAAS:[
        { title: "PR Summary", icon:"list", link: ['/pr/pr-summary'], ga: "PR Summary" },
        { title: "PR Details", icon:"list", link: ['/pr/pr-sheet'], ga: "PR Details" },
        { title: "PR Dashboard", icon:"list", link: ['/pr/pr-dashboard'], ga: "PR Dashboard" }

    ],
}