import { Injectable } from '@angular/core';
import * as moment from "moment";

import { ApiService } from './api.service';
import { environment as env } from '../../environments/environment';
import { PARAMS } from '../config/params'
import { Observable, Subject } from 'rxjs';
import { HttpClient, HttpHeaders, } from '@angular/common/http';
import * as lodash from 'lodash';
import { ActivatedRoute, Router } from '@angular/router';
import { ValidationService } from '@app/services/helpers/validation.service';

@Injectable({
  providedIn: 'root'
})
export class SaService {

  updateSa(data) {
    let url = `${env.buyerInternal.baseUrl}${PARAMS.BUYER_INTERNAL.MODULES.SA.UPDATE_SA.URL}`;
    return this.apiService.post(url, data);
  }
  getSaDetails(saId) {
    let url = `${env.buyerInternal.baseUrl}${PARAMS.BUYER_INTERNAL.MODULES.SA.GET_SA_DETAILS.URL}/${saId}`;
    return this.apiService.get(url);
  }

  getSaDetailsFromPlantIds(data) {
    let url = `${env.buyerInternal.baseUrl}${PARAMS.BUYER_INTERNAL.MODULES.SA.GET_SA_DATA_BY_PLANTID.URL}`;
    return this.apiService.post(url, data);
  }

  public getSaItems(data) {
    let url = `${env.procurement.baseUrl}${PARAMS.PROCUREMENT.MODULES.PRODUCT.FIND_BY_IDS.URL}`;
    return this.apiService.post(url, data);
  }

  public getSaSheetData(data) {
    let url = `${env.buyerInternal.baseUrl}${PARAMS.BUYER_INTERNAL.MODULES.SA.GET_SA_SHEET_DATA.URL}`;
    return this.apiService.post(url, data);
  }

  public updateSaSheetItem(data) {
    let url = `${env.buyerInternal.baseUrl}${PARAMS.BUYER_INTERNAL.MODULES.SA.UPDATE_SA_SHEET_ITEM.URL}`;
    return this.apiService.post(url, data);
  }

  public pushRealeaseSAToSCM(data) {
    let url = `${env.buyerInternal.baseUrl}${PARAMS.BUYER_INTERNAL.MODULES.SA.PUSH_TO_SCM.URL}`;
    return this.apiService.post(url, data);
  }

  async getMsnSearchData(data) {
    let url = `${env.online.baseUrl}${PARAMS.BUYER_INTERNAL.MODULES.RFQ.SEARCH_MSN.URL}?productId=${data}&fetchGroup=true`;
    return await this.apiService.get(url).toPromise();
  }

  async checkMSNSearchData(data, routeaction) {
    let result = {
      errorCheckItemMsnActive: [],
      isMsnPresent: true
    };
    let iterateData = data.items;
    for (let i = 0; i < iterateData.length; i++) {
      let item = iterateData[i];
      if (routeaction != "editSA") {
        if (lodash.has(item, 'product.activeMsn') && !this.validationService.checkEmptyOrNull(item.product.activeMsn)) {
          let responseData = await this.getMsnSearchData(item.product.activeMsn);
          if (!responseData.active) {
            result.errorCheckItemMsnActive.push(item.product.activeMsn);
          }
        }
        else {
          result.isMsnPresent = false;
        }
      }
      else {
        if (lodash.has(item, 'product.activeMsn') && !this.validationService.checkEmptyOrNull(item.product.activeMsn)) {
          let responseData = await this.getMsnSearchData(item.product.activeMsn);
          if (!responseData.active) {
            result.errorCheckItemMsnActive.push(item.product.activeMsn);
          }
        }
      }
    };
    return result;
  }

  public static readonly ACTION_CREATE = 'createSA';
  public static readonly ACTION_VIEW = 'viewSA';
  public static readonly ACTION_EDIT = 'editSA';
  public static readonly ACTION_REPEAT = 'repeatSA';
  public static readonly ACTION_EXTEND = 'extendSA';



  poObject: Array<any> = [];
  poMap: any;
  suppliers: Array<any> = [];
  purchasers: Array<any> = [];
  poIds: Array<string> = [];
  selectedPos: Array<string> = [];
  selectedPosChange: Subject<any> = new Subject<any>();
  items: Array<any> = [];
  itemsData: Array<any> = [];
  saData: any = {};
  saDataForEditSa: any = {};
  saDataWithApprovedItems: any = {};
  saDetailsForPo: any = {};
  itemDataClone: Array<any> = [];
  public readonly PO_STATUS_REJECTED = 4;

  poStatus = {
    1: 'PO Received', 2: 'PO Punched', 3: 'MIS approval pending', 4: 'MSN pending',
    5: 'Product Specs pending', 6: 'Inventory Check pending', 7: 'Vendor registration pending',
    8: 'Vendor assignment pending', 9: 'To be shared with CPT', 10: 'Shared With CPT',
    11: 'WH Inventory', 12: 'PO to supplier pending', 13: 'GM% approval pending',
    14: 'Advance Payment pending', 15: 'Supplier OD issue', 16: 'Commited Pickup date not Mentioned',
    17: 'Pick up/ Dispatch from vendor pending', 18: 'Partial Pick up/ Dispatch from vendor pending',
    19: 'Lead time product, follow up required', 20: 'On Hold', 21: 'Cancelled / Closed', 22: 'QC_FAIL',
    23: 'MRN Pending', 24: 'Invoice pending', 25: 'Partial Invoice pending', 26: 'Customer Credit Limit Exceeded',
    27: 'Dispatch pending', 28: 'Partial dispatch pending', 29: 'Delivered', 30: 'Partially Delivered',
    31: 'Returned/ Rejected by customer', 32: 'Credit Note Generated', 33: 'Issues/Clarification',
    34: 'Supplier Po Raised', 35: 'BLANK', 36: 'Order In (CPT)', 37: 'Sourcing Done (CPT)', 38: 'Pickup Aligned (CPT)',
    39: 'CPT Clarification required (CPT)', 40: 'Pickup Done (CPT)', 41: 'Approval pending (CPT)',
    42: 'Pick up pending (CPT)', 43: 'Approved (CPT)', 44: 'Sourcing Not Done (CPT)', 45: 'Rejection Pending (CPT)',
    46: 'MRN Done', 47: 'Dispatched', 48: 'Partial MRN Done', 49: 'Warehouse Inventory Allocated', 50: 'Clarified by Business team', 51: 'Approval mail shared', 52: 'Payment issue'
  };

  constructor(private apiService: ApiService, private validationService: ValidationService) {
  }

  setItemDataClone(itemDataClone) {
    this.itemDataClone = itemDataClone;
  }
  getItemDataClone(): Array<any> {
    return this.itemDataClone;
  }

  setPoObject(poObject) {
    this.poObject = poObject;
  }
  getPoObject(): Array<any> {
    return this.poObject;
  }

  setPoMap(_poMap) {
    this.poMap = _poMap;
  }
  getPoFromId(_id) {
    if (this.poMap) return this.poMap[_id];
    return null;
  }

  setPoIds(poIds) {
    localStorage.setItem('poIds', JSON.stringify(poIds));
    //this.poIds = poIds;
  }

  getPoIds(): Array<string> {
    this.poIds = JSON.parse(localStorage.getItem('poIds'));
    return this.poIds;
  }

  setSAData(saData) {
    this.saData = saData;
  }

  setSADataWithApprovedItems(saDataWithApprovedItems) {
    this.saDataWithApprovedItems = saDataWithApprovedItems;
  }

  getSADataWithApprovedItems() {
    return this.saDataWithApprovedItems;
  }


  resetSAData() {
    this.saData = {};
  }

  getSAData(): any {
    return this.saData;
  }

  setSaDataForEditSa(data) {
    this.saDataForEditSa = data;
  }
  getSaDataForEditSa() {
    return this.saDataForEditSa;
  }

  setSADetailsForPo(saDetailsForPo) {
    this.saDetailsForPo = saDetailsForPo;
  }
  getSADetailsForPo(): any {
    return this.saDetailsForPo;
  }
  resetSADetailsForPo() {
    this.saDetailsForPo = {};
  }
  getSelectedPos(): Array<any> {
    return this.selectedPos;
  }

  setSelectedPos(selectedPos: Array<string>) {
    this.selectedPos = selectedPos;
    this.selectedPosChange.next(selectedPos);
  }



  getPoState(data) {
    let result = '0';
    if (lodash.has(data, 'sapDetails.poState')) {
      if (data.sapDetails.poState === 'open') {
        result = '1';
      }
    }
    return result;
  }

  getPoReleaseState(data) {
    let result = '0';
    if (lodash.has(data, 'sapDetails.releaseFlag')) {
      if (data.sapDetails.releaseFlag === 'G' || data.sapDetails.releaseFlag === '3') {
        result = '0';
      } else {
        result = '1';
      }
    }
    return result;
  }

  /**
   * This function save PO data by handshaking with BuyerInternal backend to maintain the
   * state of data entered by user. It should not be confused with PO punching which happens
   * at Buyer platform.
   *
   * @param data
   * @author Kuldeep Dangi <kuldeep.dangi@moglix.com>
   */
  public draftPoData(data) {
    let url = `${env.buyerInternal.baseUrl}${PARAMS.BUYER_INTERNAL.MODULES.PO.DRAFT_PO_DATA.URL}`;
    return this.apiService.post(url, data);
  }


  /**
   * Return any PO data saved against a user and not submitted yet for approval
   *
   * @param data
   * @author Kuldeep Dangi <kuldeep.dangi@moglix.com>
   */
  public getDraftedPoData(userId: number) {
    let url = `${env.buyerInternal.baseUrl}${PARAMS.BUYER_INTERNAL.MODULES.PO.GET_BY_USER.URL}/${userId}`;
    return this.apiService.get(url);
  }

  public search(queryString, data) {
    let url = `${env.procurement.baseUrl}${PARAMS.PROCUREMENT.MODULES.PO.SEARCH.URL}${queryString}`;
    return this.apiService.post(url, data);
  }

  public searchPosToApprove(data) {
    let url = `${env.buyerInternal.baseUrl}${PARAMS.BUYER_INTERNAL.MODULES.PO.GET_BY_PLANTS.URL}`;
    return this.apiService.post(url, data);
  }

  public getAllSubmittedDraftByUser(data) {
    let url = `${env.buyerInternal.baseUrl}${PARAMS.BUYER_INTERNAL.MODULES.PO.GET_PO_DRAFT_DATA.URL}`;
    return this.apiService.post(url, data);
  }


  public itemSearch(queryString, data) {
    let url = `${env.procurement.baseUrl}${PARAMS.PROCUREMENT.MODULES.PO.ITEM_SEARCH.URL}${queryString}`;
    return this.apiService.post(url, data);
  }

  public getPurchasers(data): Observable<any> {
    let url = `${env.userManagement.baseUrl}${PARAMS.USER_MANAGEMENT.MODULES.PURCHASER.GET_PURCHASERS.URL}`;
    return this.apiService.post(url, data);
  }

  public getSupplierSuggestions(key: string): Observable<any> {
    // let url = `${env.suppliers.baseUrl}${PARAMS.USER_MANAGEMENT.MODULES.SUPPLIERS.SEARCH.URL}?key=${key}`;
    let url = `${env.searchSupplier.baseUrl}${PARAMS.USER_MANAGEMENT.MODULES.SUPPLIERS.SEARCH_SUPPLIER.URL}?key=${key}`;
    return this.apiService.get(url);
  }

  public getPaymentTerms(groupId: number, plantId: number): Observable<any> {
    let url = `${env.procurement.baseUrl}${PARAMS.PROCUREMENT.MODULES.PO.PAYMENT_TERMS.URL}${groupId}/buyer/${plantId}`;
    return this.apiService.get(url);
  }

  public getDetails(id: string): Observable<any> {
    let url = `${env.procurement.baseUrl}${PARAMS.PROCUREMENT.MODULES.PO.DETAIL.URL}${id}`;
    return this.apiService.get(url);
  }

  public getTimeFromTimestamp(ts, isNoTime?: boolean) {
    let d = new Date(ts);
    let dateFormatString = "MM/DD/YYYY hh:mm a";
    if (isNoTime) dateFormatString = "MM/DD/YYYY";
    let dateString = moment(d).format(dateFormatString);
    return dateString;
  }

  public getAddressses(data): Observable<any> {
    let url = `${env.userManagement.baseUrl}${PARAMS.USER_MANAGEMENT.MODULES.PLANTS.GET_ADDRESS_BY_BRANCH.URL}`;
    return this.apiService.post(url, data);
  }


  fileUpload(url: string, body?: any) {
    return this.apiService.post(url, body);
  }

  createSA(data): Observable<any> {
    let url = `${env.buyerInternal.baseUrl}${PARAMS.BUYER_INTERNAL.MODULES.SA.CREATE.URL}`;
    return this.apiService.post(url, data);
  }
  /**
   * API call for repeat SA submit
   * @param data request body for repeat SA
   * @returns 
   */
  repeatSA(data): Observable<any> {
    let url = `${env.buyerInternal.baseUrl}${PARAMS.BUYER_INTERNAL.MODULES.SA.REPEAT.URL}`;
    return this.apiService.post(url, data);
  }

  cancelPo(poId: string, data: any): Observable<any> {
    let url = `${env.procurement.baseUrl}${PARAMS.PROCUREMENT.MODULES.PO.DETAIL.URL}` + poId + `/cancel`;
    console.log(url);
    return this.apiService.post(url, data);
  }

  getTaxRateFromHsn(data: any): Observable<any> {
    let url = `${env.procurement.baseUrl}${PARAMS.PROCUREMENT.MODULES.PRODUCT.GET_TAX_RATE_FROM_HSN.URL}`;
    return this.apiService.post(url, data);
  }

  public itemSearchFromMongo(url: string, data: any) {
    return this.apiService.post(url, data);
  }

  public productSearch(queryString, data) {
    let url = `${env.procurement.baseUrl}${PARAMS.PROCUREMENT.MODULES.PRODUCT.FETCH_ALL.URL}${queryString}`;
    return this.apiService.post(url, data);
  }

  public getPo(id: string): Observable<any> {
    let url = `${env.procurement.baseUrl}${PARAMS.PROCUREMENT.MODULES.PO.GET_BY_ID.URL}`;
    return this.apiService.get(url + id);
  }

  public getDraftPo(id: string): Observable<any> {
    let url = `${env.buyerInternal.baseUrl}${PARAMS.BUYER_INTERNAL.MODULES.PO.GET_PO_DATA.URL}`;
    return this.apiService.get(url + id);
  }

  updatePo(id: string, data: any): Observable<any> {
    let url = `${env.procurement.baseUrl}${PARAMS.PROCUREMENT.MODULES.PO.UPDATE_PO.URL}` + id + `/update`;
    return this.apiService.post(url, data);
  }

  updateItem(id: string, data: any): Observable<any> {
    let url = `${env.procurement.baseUrl}${PARAMS.PROCUREMENT.MODULES.PO.UPDATE_ITEM.URL}` + id + `/update`;
    return this.apiService.post(url, data);
  }

  cancelAndClonePo(id: string, data: any): Observable<any> {
    let url = `${env.procurement.baseUrl}${PARAMS.PROCUREMENT.MODULES.PO.UPDATE_PO.URL}` + id + `/cancelAndClone`;
    return this.apiService.post(url, data);
  }

  cancelAndCloneItem(id: string, data: any): Observable<any> {
    let url = `${env.procurement.baseUrl}${PARAMS.PROCUREMENT.MODULES.PO.UPDATE_PO.URL}` + id + `/cancelAndCloneItem`;
    return this.apiService.post(url, data);
  }

  uploadItemExcel(itemFile) {
    let url = `${env.buyerInternal.baseUrl}${PARAMS.BUYER_INTERNAL.MODULES.PO.UPLOAD_ITEM_EXCEL.URL}`;
    return this.apiService.post(url, itemFile);
  }

  downloadTemplateExcel() {
    let url = `${env.buyerInternal.baseUrl}${PARAMS.BUYER_INTERNAL.MODULES.PO.DOWNLOAD_TEMPLATE.URL}`;
    return this.apiService.get(url);
  }


  public getSaItemSheetCsvData(data) {
    let url = `${env.buyerInternal.baseUrl}${PARAMS.BUYER_INTERNAL.MODULES.SA.GET_SA_ITEMSHEET_CSV_DATA.URL}`;
    return this.apiService.post(url, data);
  }

  public getAuditLogDataForSaItem(data) {
    let url = `${env.buyerInternal.baseUrl}${PARAMS.BUYER_INTERNAL.MODULES.SA.GET_AUDIT_LOG_FOR_SA_ITEM.URL}`;
    return this.apiService.post(url, data);
  }

  /**
   * api call to extend validity of a SA
   * @param data request body consisting said and end-date
   */
  extenSaValidity(data) {
    let url = `${env.buyerInternal.baseUrl}${PARAMS.BUYER_INTERNAL.MODULES.SA.EXTEND_SA_VALIDITY.URL}`;
    return this.apiService.post(url, data);
  }

}



